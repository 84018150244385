import React, { useState, useEffect } from 'react';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import Layout from "../components/layout/layout";

import indexSystemImage1 from '../images/system/sys_ph01.jpg';
import indexSystemImage2 from '../images/system/sys_ph02.jpg';
import indexSystemImage3 from '../images/system/sys_ph03.jpg';
import indexSystemImage4 from '../images/system/sys_ph04.jpg';
import indexSystemImage5 from '../images/system/sys_ph05.jpg';
import indexSystemImage6 from '../images/system/sys_ph06.jpg';
import indexSystemImage7 from '../images/system/sys_ph07.jpg';
import indexSystemImage8 from '../images/system/sys_ph08.jpg';
import indexSystemImage9 from '../images/system/sys_ph09.jpg';
import indexSystemImage10 from '../images/system/sys_ph10.jpg';
import indexSystemImage11 from '../images/system/sys_ph11.jpg';

import { graphql } from 'gatsby';

import { Helmet } from 'react-helmet';

const M = typeof window !== 'undefined' ? require('materialize-css/dist/js/materialize.min.js') : null;

const System = ({ data }) => {
    const { indexImage, indexImageLightBox1, indexImageLightBox2, indexImageLightBox3, indexImageLightBox4, indexImageLightBox5, 
            indexImageLightBox6, indexImageLightBox7, indexImageLightBox8 } = data;
    
    useEffect(() => {
        var modals = document?.querySelectorAll('.modal');
        var modalInstances = M?.Modal?.init(modals, { });

        var carousel = document?.querySelector('.carousel');
        var carouselInstances = M?.Carousel?.init(carousel, { })
    }, []);

    return (
        <Layout location="system" metadata={ { title: "業務・設備｜株式会社テストサービス" } }>
            <main className="page--system page">
				<noscript>JavaScript を適切に有効にする必要があります</noscript>
                <div class="section-system-jumbotron-wrap">
                    <section className="section-jumbotron--system section-jumbotron">
                        <BackgroundImage className="section-jumbotron__background" fluid={ indexImage.childImageSharp.fluid }>
                            <div className="section-jumbotron__background-overlay">
                                <div className="section-jumbotron__text-box">
                                    <h1 className="section-jumbotron__header">
                                        <span className="section-jumbotron__header--main">業務・設備</span>
                                    </h1>
                                </div>
                            </div> 
                        </BackgroundImage>
                    </section>
                </div>
                    <section class="table">
    			<div class="container">
    				<div class="row">
    					<h3>業務内容</h3>
    					<hr />
    				</div>
    				<div class="row">
    					<table class="centered type-one">
    						<tr>
    							<td rowspan="10">検査業務</td>
    							<td rowspan="5">フレッシュコンクリート性状試験</td>
    							<td>スランプ試験</td>
    							<td>JIS A 1101</td>
    						</tr>
    						<tr>
    							<td>スランプフロー試験</td>
    							<td>JIS A 1150</td>
    						</tr>
    						<tr>
    							<td>空気量測定</td>
    							<td>JIS A 1128</td>
    						</tr>
    						<tr>
    							<td>塩化物量測定</td>
    							<td>JASS 5T-502</td>
    						</tr>
    						<tr>
    							<td>供試体の作成</td>
    							<td>JIS A 1132</td>
    						</tr>
    						<tr>
    							<td rowspan="4">フレッシュコンクリートの単位水量試験</td>
    							<td colspan="2">エアメータ法</td>
    						</tr>
    						<tr>
    							<td colspan="2">高周波加熱法</td>
    						</tr>
    						<tr>
    							<td colspan="2">静電容量法</td>
    						</tr>
    						<tr>
    							<td colspan="2">水中質量法</td>
    						</tr>
    						<tr>
    							<td>鉄筋継手部超音波深傷検査</td>
    							<td colspan="2">ガス圧接、溶接、機械式継手部</td>
    						</tr>
    					</table>
    				</div>
    			</div>
    		</section>
    		<section class="img">
    			<div class="container">
    				<div class="row">
    					<div class="system-image-flex-container">
                            <img className="responsive-img" src={ indexSystemImage9 } />
                            <img className="responsive-img" src={ indexSystemImage10 } />
                            <img className="responsive-img" src={ indexSystemImage11 } />
                        </div>
    				</div>
    			</div>
    		</section>
    		<section class="table">
    			<div class="container">
    				<table class="centered type-one">
    					<tr>
    						<td rowspan="7">試験業務</td>
    						<td>普通及び高強度コンクリート圧縮強度試験</td>
    						<td>JIS A 1108</td>
    						<td>-</td>
    					</tr>
    					<tr>
    						<td rowspan="3">鉄筋試験JIS Z 3120</td>
    						<td>引張試験</td>
    						<td>JIS Z 2241</td>
    					</tr>
    					<tr>
    						<td>曲げ試験</td>
    						<td>JIS Z 2248</td>
    					</tr>
    					<tr>
    						<td>素材試験</td>
    						<td>JIS G 3112、3191</td>
    					</tr>
    					<tr>
    						<td>コンクリートの中性化深さ試験</td>
    						<td colspan="2">JIS A 1152</td>
    					</tr>
    					<tr>
    						<td>リバウンドハンマーによる強度測定調査</td>
    						<td colspan="2">JIS A 1155</td>
    					</tr>
    					<tr>
    						<td>コンクリート又はコアのヤング係数測定</td>
    						<td colspan="2">JIS A 1149</td>
    					</tr>
    				</table>
    			</div>
    		</section>
    		<section class="table">
    			<div class="container">
    				<div class="row">
    					<h3>設備機器一覧</h3>
    					<hr />
    				</div>
    				<div class="row">
    					<table class="striped type-two">
    						<thead>
    							<tr>
    								<th>設備機器</th>
    								<th>摘要</th>
    								<th>台数</th>
    							</tr>
    						</thead>
    						<tbody>
    							<tr>
    								<td>2000kN圧縮試験機<a class="modal-trigger" href="#ph01"><i class="material-icons red-text">search</i>写真をみる</a></td>
    								<td>遠景</td>
    								<td>-</td>
    							</tr>
    							<tr>
    								<td>2000kN圧縮試験機<a class="modal-trigger" href="#ph02"><i class="material-icons red-text">search</i>写真をみる</a></td>
    								<td>AC-2000SIV　　東京衡機試験機</td>
    								<td>1台</td>
    							</tr>
    							<tr>
    								<td>2000kN圧縮試験機<a class="modal-trigger" href="#ph03"><i class="material-icons red-text">search</i>写真をみる</a></td>
    								<td>CONCRETO 2000　　島津製作所</td>
    								<td>2台</td>
    							</tr>
    							<tr>
    								<td>2000kN万能試験機<a class="modal-trigger" href="#ph04"><i class="material-icons red-text">search</i>写真をみる</a></td>
    								<td>YU-2000SIV　　東京衡機試験機</td>
    								<td>1台</td>
    							</tr>
    							<tr>
    								<td>標準養生水槽<a class="modal-trigger" href="#ph05"><i class="material-icons red-text">search</i>写真をみる</a></td>
    								<td>24.8m³</td>
    								<td>容量約3000セット</td>
    							</tr>
    							<tr>
    								<td>屋外水中養生水槽<a class="modal-trigger" href="#ph06"><i class="material-icons red-text">search</i>写真をみる</a></td>
    								<td>約11.0m³</td>
    								<td>容量約1500セット</td>
    							</tr>
    							<tr>
    								<td>供試体端面研磨機<a class="modal-trigger" href="#ph07"><i class="material-icons red-text">search</i>写真をみる</a></td>
    								<td>ハイケンマつるつる　マルイ</td>
    								<td>6台</td>
    							</tr>
    						</tbody>
    					</table>
    				</div>
    				<div class="row">
    					<table class="centered striped type-two">
    						<thead>
    							<tr>
    								<th colspan="2">その他設備機器</th>
    							</tr>
    						</thead>
    						<tbody>
    							<tr>
    								<td>自動記録式温度計</td>
    								<td>超音波探傷検査器</td>
    							</tr>
    							<tr>
    								<td>キャッピング装置</td>
    								<td>質量測定器</td>
    							</tr>
    							<tr>
    								<td>平面度検査器</td>
    								<td>寸法測定器</td>
    							</tr>
    							<tr>
    								<td>リバウンドハンマー</td>
    								<td>コンプレッソメーター<a class="modal-trigger" href="#ph08"><i class="material-icons red-text">search</i>写真をみる</a></td>
    							</tr>
    						</tbody>
    					</table>
    				</div>
    			</div>
    		</section>
    		<section class="gallery">
    			<div class="carousel">
    				<img class="carousel-item" src={ indexSystemImage1 } />
    				<img class="carousel-item" src={ indexSystemImage2 } />
    				<img class="carousel-item" src={ indexSystemImage3 } />
    				<img class="carousel-item" src={ indexSystemImage4 } />
    				<img class="carousel-item" src={ indexSystemImage5 } />
    				<img class="carousel-item" src={ indexSystemImage6 } />
    				<img class="carousel-item" src={ indexSystemImage7 } />
    				<img class="carousel-item" src={ indexSystemImage8 } />
    			</div>
    		</section>
    		<section class="modals">
    			<div class="modal" id="ph01">
    				<div class="modal-content">
    					<Image fluid={ indexImageLightBox1.childImageSharp.fluid } />
    				</div>
    			</div>
    			<div class="modal" id="ph02">
    				<div class="modal-content">
                    <Image fluid={ indexImageLightBox2.childImageSharp.fluid } />
    				</div>
    			</div>
    			<div class="modal" id="ph03">
    				<div class="modal-content">
                    <Image fluid={ indexImageLightBox3.childImageSharp.fluid } />
    				</div>
    			</div>
    			<div class="modal" id="ph04">
    				<div class="modal-content">
                    <Image fluid={ indexImageLightBox4.childImageSharp.fluid } />
    				</div>
    			</div>
    			<div class="modal" id="ph05">
    				<div class="modal-content">
                    <Image fluid={ indexImageLightBox5.childImageSharp.fluid } />
    				</div>
    			</div>
    			<div class="modal" id="ph06">
    				<div class="modal-content">
                    <Image fluid={ indexImageLightBox6.childImageSharp.fluid } />
    			</div>
    			</div>
    			<div class="modal" id="ph07">
    				<div class="modal-content">
                    <Image fluid={ indexImageLightBox7.childImageSharp.fluid } />
    			</div>
    			</div>
    			<div class="modal" id="ph08">
    				<div class="modal-content">
                    <Image fluid={ indexImageLightBox8.childImageSharp.fluid } /> 
    			</div>
    			</div>
    		</section>
            </main>
        </Layout>
    )
}

export const query = graphql`
{
  indexImage: file(relativePath: {eq: "stock/placeholder.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImageLightBox1: file(relativePath: {eq: "system/photo01.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImageLightBox2: file(relativePath: {eq: "system/photo02.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImageLightBox3: file(relativePath: {eq: "system/photo03.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImageLightBox4: file(relativePath: {eq: "system/photo04.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImageLightBox5: file(relativePath: {eq: "system/photo05.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImageLightBox6: file(relativePath: {eq: "system/photo06.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImageLightBox7: file(relativePath: {eq: "system/photo07.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImageLightBox8: file(relativePath: {eq: "system/photo08.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
}
`;

export default System;
